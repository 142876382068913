<div class="container-fluid algorithm-container">
    <div class="row">
        <div class="algorithm-selector d-flex m-0 p-0">
            <div class="d-flex flex-column justify-content-center user-label p-4">
                {{ 'admin.components.adminToken.user' | translate }}:
            </div>
            <div class="tryb-dropdown mt-4 mb-4">
                <select
                    [formControl]="userForm"
                    (keyup.enter)="onUserSelectInteraction($event)"
                    (keyup.escape)="onUserSelectInteraction($event)">
                    <option
                        *ngFor="let user of users"
                        [ngValue]="user">
                        {{ user.displayName }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="selectedUser">
    <table id="token-list" class="table table-striped">
        <caption hidden>
            Token List
        </caption>
        <thead class="table-dark">
            <tr>
                <th scope="col" class="token-col">
                    {{ 'admin.components.adminToken.token' | translate }}
                </th>
                <th scope="col" class="activate-col">
                    {{ 'admin.components.adminToken.action' | translate }}
                </th>
                <th class="delete-col">
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let token of tokens">
                <td class="text-break">{{token.opaqueToken}}</td>
                <td>
                    <mat-slide-toggle
                        class="user-settings user-toggle"
                        color="primary"
                        [checked]="token.active"
                        (change)="onActiveToggleChange(selectedUser, token)">
                        {{
                            token.active ? 'Active' : 'Inactive'
                        }}
                    </mat-slide-toggle>
                </td>
                <td>
                    <span 
                        class="bi bi-trash icon delete-button m-5 mt-0 mb-0"
                        (click)="onTokenDeleteButtonClick(selectedUser, token)"></span>
                </td>
            </tr>
        </tbody>
    </table>
    
    <button (click)="onTokenAddButtonClick(selectedUser)">Add Token</button>
</ng-container>
