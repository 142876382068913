<div class="container-fluid p-0">
    <div class="row">
        <div style="margin-top: 10px"></div>
        <div class="col-2">
            <div class="elm-detail-wrapper elements-list-container">
                <p class="title">
                    {{ 'neuralNetwork.observations.sideBarHeader' | translate }}
                </p>
                <ul class="scene-elements-list tryb-scroll">
                    <li
                        *ngFor="let sceneElement of sceneElements; index as i"
                        (click)="onSelectElement(i)"
                        class="scene-element"
                        [class.selected]="selectedElementIndex === i"
                    >
                        {{ sceneElement.browserAndPhysicsLoadable.name }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-10">
            <div class="elm-detail-wrapper elements-details-container">
                <div *ngIf="sceneElements.length > 0">
                    <h3>
                        {{
                            sceneElements[selectedElementIndex]
                                .browserAndPhysicsLoadable.name
                        }}
                    </h3>
                    <div class="tables-container tryb-scroll">
                        <div
                            *ngIf="
                                observations.observations[selectedElementIndex]
                                    .poseObservations !== undefined
                            "
                        >
                            <p><strong>Pose Observations</strong></p>

                            <table class="table table-hover">
                                <caption hidden>
                                    List of Pose Observations
                                </caption>
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">x</th>
                                        <th scope="col">y</th>
                                        <th scope="col">z</th>
                                        <th scope="col">orientation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="
                                            let poseObservation of observations
                                                .observations[
                                                selectedElementIndex
                                            ].poseObservations
                                        "
                                    >
                                        <td>{{ poseObservation.name }}</td>
                                        <td>
                                            <label class="tryb-checkbox">
                                                <input
                                                    type="checkbox"
                                                    [attr.id]="
                                                        'checkbox-' +
                                                        poseObservation.name +
                                                        '-x'
                                                    "
                                                    [formControl]="
                                                        form.get(
                                                            poseObservation.name +
                                                                '-x'
                                                        )
                                                    "
                                                    (change)="onChangeValue()"
                                                />
                                                <span
                                                    class="checkmark"
                                                    [class.disabled]="
                                                        editingIsDisabled
                                                    "
                                                >
                                                </span>
                                            </label>
                                        </td>
                                        <td>
                                            <label class="tryb-checkbox">
                                                <input
                                                    type="checkbox"
                                                    [attr.id]="
                                                        'checkbox-' +
                                                        poseObservation.name +
                                                        '-y'
                                                    "
                                                    [formControl]="
                                                        form.get(
                                                            poseObservation.name +
                                                                '-y'
                                                        )
                                                    "
                                                    (change)="onChangeValue()"
                                                />
                                                <span
                                                    class="checkmark"
                                                    [class.disabled]="
                                                        editingIsDisabled
                                                    "
                                                >
                                                </span>
                                            </label>
                                        </td>
                                        <td>
                                            <label class="tryb-checkbox">
                                                <input
                                                    type="checkbox"
                                                    [attr.id]="
                                                        'checkbox-' +
                                                        poseObservation.name +
                                                        '-z'
                                                    "
                                                    [formControl]="
                                                        form.get(
                                                            poseObservation.name +
                                                                '-z'
                                                        )
                                                    "
                                                    (change)="onChangeValue()"
                                                />
                                                <span
                                                    class="checkmark"
                                                    [class.disabled]="
                                                        editingIsDisabled
                                                    "
                                                >
                                                </span>
                                            </label>
                                        </td>
                                        <td>
                                            <label class="tryb-checkbox">
                                                <input
                                                    type="checkbox"
                                                    [attr.id]="
                                                        'checkbox-' +
                                                        poseObservation.name +
                                                        '-orientation'
                                                    "
                                                    [formControl]="
                                                        form.get(
                                                            poseObservation.name +
                                                                '-orientation'
                                                        )
                                                    "
                                                    (change)="onChangeValue()"
                                                />
                                                <span
                                                    class="checkmark"
                                                    [class.disabled]="
                                                        editingIsDisabled
                                                    "
                                                >
                                                </span>
                                            </label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div
                            *ngIf="
                                observations.observations[selectedElementIndex]
                                    .jointObservations !== undefined
                            "
                        >
                            <p><strong>Joint Observations</strong></p>

                            <table class="table table-hover">
                                <caption hidden>
                                    List of Joint Observations
                                </caption>
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">angle</th>
                                        <th scope="col">velocity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="
                                            let jointObservation of observations
                                                .observations[
                                                selectedElementIndex
                                            ].jointObservations
                                        "
                                    >
                                        <td>{{ jointObservation.name }}</td>
                                        <td>
                                            <label class="tryb-checkbox">
                                                <input
                                                    type="checkbox"
                                                    [attr.id]="
                                                        'checkbox-' +
                                                        jointObservation.name +
                                                        '-angle'
                                                    "
                                                    [formControl]="
                                                        form.get(
                                                            jointObservation.name +
                                                                '-angle'
                                                        )
                                                    "
                                                    (change)="onChangeValue()"
                                                />
                                                <span
                                                    class="checkmark"
                                                    [class.disabled]="
                                                        editingIsDisabled
                                                    "
                                                >
                                                </span>
                                            </label>
                                        </td>

                                        <td>
                                            <label class="tryb-checkbox">
                                                <input
                                                    type="checkbox"
                                                    [attr.id]="
                                                        'checkbox-' +
                                                        jointObservation.name +
                                                        '-velocity'
                                                    "
                                                    [formControl]="
                                                        form.get(
                                                            jointObservation.name +
                                                                '-velocity'
                                                        )
                                                    "
                                                    (change)="onChangeValue()"
                                                />
                                                <span
                                                    class="checkmark"
                                                    [class.disabled]="
                                                        editingIsDisabled
                                                    "
                                                >
                                                </span>
                                            </label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div
                            *ngIf="
                                observations.observations[selectedElementIndex]
                                    .customObservations !== undefined
                            "
                        >
                            <p><strong>Custom Observations</strong></p>

                            <table class="table table-hover">
                                <caption hidden>
                                    List of Custom Observations
                                </caption>
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">active</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="
                                            let customObservation of observations
                                                .observations[
                                                selectedElementIndex
                                            ].customObservations
                                        "
                                    >
                                        <td>{{ customObservation.name }}</td>
                                        <td>
                                            <label class="tryb-checkbox">
                                                <input
                                                    type="checkbox"
                                                    [attr.id]="
                                                        'checkbox-' +
                                                        customObservation.name +
                                                        '-active'
                                                    "
                                                    [formControl]="
                                                        form.get(
                                                            customObservation.name +
                                                                '-active'
                                                        )
                                                    "
                                                    (change)="onChangeValue()"
                                                />
                                                <span
                                                    class="checkmark"
                                                    [class.disabled]="
                                                        editingIsDisabled
                                                    "
                                                >
                                                </span>
                                            </label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
