<div
    class="d-flex justify-content-center align-items-center h-100"
    *ngIf="images.length === 0"
>
    <p>{{ 'imgDb.upload.dropFileMessage' | translate }}</p>
</div>
<div class="img-db-gallery-content overflow-auto">
    <div class="container" *ngIf="images.length">
        <div class="row">
            <div
                class="fotocard-container to-zoom"
                [ngStyle]="{ width: currentWidth + '%' }"
                *ngFor="let image of images"
            >
                <div
                    class="fotocard"
                    (click)="selectImage(image)"
                    [ngClass]="{ 'img-selected': image.isSelected }"
                >
                    <div
                        [ngStyle]="{
                            'background-image': 'url(' + image.url + ')'
                        }"
                        class="img-card"
                    >
                        <div class="selection-marker"></div>
                    </div>
                    <span>{{ image.name }}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="d-flex justify-content-center">
    <app-input-paging-bar
        tag="manage"
        [maxValue]="totalPages"
        [currentValue]="currentPage"
        (nextValue)="getNextPage($event)"
    ></app-input-paging-bar>
</div>
