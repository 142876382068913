import {
    Component,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { Scene } from 'src/app/scenes/models/scene';
import { SceneElement } from 'src/app/scenes/models/scene-element';
import { SceneService } from 'src/app/scenes/services/scene.service';
import { TrainingService } from 'src/app/shared/services/training.service';
import {
    Observation,
    ObservationForElement,
    ObservationSpace,
} from '../interfaces/observation';

@Component({
    selector: 'app-observation-element-details',
    templateUrl: './observation-element-details.component.html',
    styleUrls: ['./observation-element-details.component.scss'],
})
export class ObservationElementDetailsComponent implements OnInit, OnDestroy {
    @Input()
    editingIsDisabled: boolean;
    @Input()
    trainingId: string;
    subscriptions: Subscription[] = [];
    sceneElements: SceneElement[] = [];
    selectedElementIndex: number = 0;
    observations: ObservationSpace;
    form: FormGroup;
    changed: boolean = false;
    constructor(
        private trainingService: TrainingService,
        private sceneService: SceneService,
        private authenticationService: AuthenticationService
    ) {}
    ngOnInit(): void {
        this.trainingService
            .getObservations(this.trainingId)
            .pipe(
                tap((observations: ObservationSpace) => {
                    this.observations = observations;
                }),
                switchMap(() => this.sceneService.getScene(this.trainingId))
            )
            .subscribe((scene: Scene) => {
                if (this.observations !== null) {
                    this.sceneElements = [];
                    this.constructFormControlsForSelectedSceneElement(
                        this.selectedElementIndex
                    );

                    this.observations.observations.forEach(
                        (observation: ObservationForElement) => {
                            this.sceneElements.push(
                                scene.sceneElements[
                                    scene.sceneElements.findIndex(
                                        (sceneElement: SceneElement) =>
                                            sceneElement.id ===
                                            observation.sceneElementId
                                    )
                                ]
                            );
                        }
                    );
                }
            });

        this.subscriptions.push(
            this.authenticationService.logoutTriggered$.subscribe(() => {
                this.saveSelectionState().subscribe(() => {
                    this.authenticationService.logoutPreparationsFinished$.next(
                        true
                    );
                });
            })
        );
    }

    onChangeValue() {
        this.changed = true;
    }

    onSelectElement(sceneElementIndex: number) {
        if (this.changed) {
            this.updateObservationSelection();
        }

        this.constructFormControlsForSelectedSceneElement(sceneElementIndex);
        this.selectedElementIndex = sceneElementIndex;
    }

    private constructFormControlsForSelectedSceneElement(
        sceneElementIndex: number
    ) {
        let formPrototype = {};
        const observationsForElement: ObservationForElement =
            this.observations.observations[sceneElementIndex];
        Object.keys(observationsForElement)
            .filter(
                (observationType: string) =>
                    observationType !== 'sceneElementId'
            )
            .forEach((observationType: string) => {
                observationsForElement[observationType].forEach(
                    (observation: Observation) => {
                        Object.keys(observation)
                            .filter(
                                (key: string) =>
                                    key !== 'name' &&
                                    key !== 'poseObservationType'
                            )
                            .forEach((key: string) => {
                                let formControl = new FormControl<boolean>(
                                    observation[key] as boolean
                                );
                                if (this.editingIsDisabled) {
                                    formControl.disable({
                                        onlySelf: true,
                                        emitEvent: false,
                                    });
                                }
                                formPrototype[`${observation.name}-${key}`] =
                                    formControl;
                            });
                    }
                );
            });
        this.form = new FormGroup(formPrototype);
    }

    updateObservationSelection() {
        const observationsForElement: ObservationForElement =
            this.observations.observations[this.selectedElementIndex];
        Object.keys(observationsForElement)
            .filter(
                (observationType: string) =>
                    observationType !== 'sceneElementId'
            )
            .forEach((observationType: string) => {
                observationsForElement[observationType].forEach(
                    (observation: Observation) => {
                        Object.keys(observation)
                            .filter(
                                (key: string) =>
                                    key !== 'name' &&
                                    key !== 'poseObservationType'
                            )
                            .forEach((key: string) => {
                                const control = this.form.get(
                                    `${observation.name}-${key}`
                                );
                                if (control !== null) {
                                    observation[key] = control.value;
                                }
                            });
                    }
                );
            });
        this.changed = false;
    }

    saveSelectionState(): Observable<ObservationSpace> {
        if (this.observations !== null) {
            if (this.changed) {
                this.updateObservationSelection();
            }
            return this.trainingService.updateObservations(
                this.trainingId,
                this.observations
            );
        } else {
            return of(null);
        }
    }

    ngOnDestroy(): void {
        this.saveSelectionState().subscribe();
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event) {
        this.saveSelectionState().subscribe();
    }
}
