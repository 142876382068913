import { Subject } from 'rxjs';

export enum RobotType {
    PIB = 'PIB',
    ANT = 'ANT',
    NANOSAUR = 'NANOSAUR',
    FRANKA_PANDA = 'FRANKA_EMIKA_PANDA',
}

export enum DatabaseType {
    CUSTOM = 'CUSTOM',
    COCO = 'COCO',
    EMPTY = 'EMPTY',
}

export enum WizardType {
    CARD = 'card',
    FORM = 'form',
}

export interface WizardSlide {
    id: number;
    root: boolean;
    type: WizardType;
    slideTitle?: string;
    slideContent: Subject<Array<WizardSlideContentElement>>;
}

/** Helper Model for Creation, where the seletion Value (cardValue)
 * and further UI representation attributes can be defined.
 */
export interface WizardSlideContentElement {
    image: string;
    cardTitle: string;
    cardDisc: string;
    redirectSlideId: number;
    cardValue?: any;
    /**
     * @deprecated use cardValue + redirecslideId for selection Value evaluation
     */
    databaseType?: DatabaseType;
    /**
     * @deprecated use cardValue + redirecslideId for selection Value evaluation
     */
    databaseId?: string;
    /**
     * @deprecated use cardValue + redirecslideId for selection Value evaluation
     */
    robotType?: RobotType;
}
