import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { Observable, catchError, lastValueFrom, throwError } from 'rxjs';
import { MessageService } from 'src/app/message/services/message.service';

@Injectable()
export class ImgDbUploadService {
    private readonly MAX_FILE_SIZE: number = 10485760;
    private readonly MIN_FILE_SIZE: number = 0;
    readonly restUrl: string;
    valid: boolean;
    uploadProcessState: EventEmitter<boolean> = new EventEmitter<boolean>();

    readonly VALID_FILE_FORMATS: string[] = ['image/jpeg'];

    constructor(
        private http: HttpClient,
        private messageService: MessageService
    ) {
        this.restUrl = environment.backendUrl + '/rest/img-db';
    }

    async processImageFilesUpload(imgDbId: string, files: NgxFileDropEntry[]) {
        for (const droppedFile of files) {
            await this.processSingleImageUpload(droppedFile, imgDbId);
        }
    }

    private async processSingleImageUpload(
        droppedFile: NgxFileDropEntry,
        imgDbId: string
    ) {
        if (!droppedFile.fileEntry.isFile) {
            this.messageService.displayTranslatedErrorMessage(
                'imgDb.upload.error.notFile',
                { filename: droppedFile.fileEntry.name }
            );
            return;
        }
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        await this.getFileFromFileEntry(fileEntry).then(async (file) => {
            this.valid = this.validateFileBeforUpload(file);
            if (!this.valid) {
                return;
            }
            this.messageService.displayTranslatedSuccessMessage(
                'imgDb.upload.uploadStartMessage',
                { filename: file.name }
            );

            const $source = this.uploadFile(imgDbId, file).pipe(
                catchError(this.handleError.bind(this))
            );

            await lastValueFrom($source).then(() => {
                this.messageService.displayTranslatedSuccessMessage(
                    'imgDb.upload.uploadSuccessMessage',
                    { filename: file.name }
                );
                this.uploadProcessState.emit(true);
            });
        });
    }

    private handleError(error: HttpErrorResponse) {
        if (
            error.status === 500 &&
            error.error.type === 'FileProcessingException'
        ) {
            this.messageService.displayTranslatedErrorMessage(
                'imgDb.upload.error.notValidFile',
                null
            );
        } else {
            this.messageService.displayTranslatedErrorMessage(
                'imgDb.upload.error.undefined',
                null
            );
        }

        return throwError(() => new Error(error.error.message));
    }

    private uploadFile(imgDbId: string, file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post(`${this.restUrl}/${imgDbId}/upload`, formData);
    }

    private async getFileFromFileEntry(
        fileEntry: FileSystemFileEntry
    ): Promise<File> {
        return new Promise((resolve) => fileEntry.file(resolve));
    }

    validateFileBeforUpload(file: File): boolean {
        let result: boolean = true;
        this.VALID_FILE_FORMATS.forEach((value) => {
            if (file.type !== value) {
                this.messageService.displayTranslatedErrorMessage(
                    'imgDb.upload.error.fileType',
                    { filename: file.name }
                );
                console.error(
                    `File ${file.name} has invalid type "${file.type}"`
                );
                result = false;
            }
        });

        if (file.size > this.MAX_FILE_SIZE) {
            this.messageService.displayTranslatedErrorMessage(
                'imgDb.upload.error.fileSize',
                { filename: file.name }
            );
            console.error(`File ${file.name} is larger than 10MB`);
            return false;
        }

        if (file.size === this.MIN_FILE_SIZE) {
            this.messageService.displayTranslatedErrorMessage(
                'imgDb.upload.error.fileNotEmpty',
                { filename: file.name }
            );
            console.error(`File ${file.name} is empty`);
            return false;
        }
        return result;
    }
}
