import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    DatabaseType,
    WizardSlideContentElement,
    WizardSlide,
} from '../../../project/models/wizard-slide';
import { FormGroup } from '@angular/forms';
import { MessageService } from '../../../message/services/message.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ImgDbService } from '../../services/img-db.service';
import { fadeIn, fadeOut } from 'src/app/shared/animations';
import { BehaviorSubject } from 'rxjs';
import { ContainerCreateResponse } from '../../../shared/services/container/container.types';

@Component({
    selector: 'app-img-db-wizard-slide',
    templateUrl: './img-db-wizard-slide.component.html',
    styleUrls: ['./img-db-wizard-slide.component.scss'],
    animations: [fadeIn, fadeOut],
})
export class ImgDbWizardSlideComponent {
    hasSubmitFailed = new BehaviorSubject<boolean>(false);
    databaseTypeParameter: DatabaseType;
    @Input() wizardList: Array<WizardSlide>;
    @Input() activeWizardSlide: number = 1;
    form: FormGroup;
    formControlName: string = 'dbName';

    @Output() activeWizardSlideIndex: EventEmitter<number> =
        new EventEmitter<number>();

    @Output() addDb: EventEmitter<void> = new EventEmitter<void>();
    chosenDbId: string;

    constructor(
        private imgDbService: ImgDbService,
        private messageService: MessageService,
        private router: Router,
        private translate: TranslateService
    ) {}

    onSubmit(imgDbName: string): void {
        this.imgDbService.createImageDb(imgDbName, this.chosenDbId).subscribe({
            next: (response: ContainerCreateResponse) => {
                this.confirmCreatedDb(imgDbName);
                this.navigateToManageDialog(response.id);
            },
            error: (err) => {
                console.error(err);
                this.hasSubmitFailed.next(true);
                this.declineCreateDb();
            },
        });
    }

    private navigateToManageDialog(dbId: string) {
        this.router.navigate([`/imgdb/${dbId}`]);
    }

    private confirmCreatedDb(imgDbName: string) {
        this.messageService.displaySuccessMessage(
            this.translate.instant('imgDb.events.createSuccess', {
                name: imgDbName,
            })
        );
        this.addDb.emit();
    }

    private declineCreateDb() {
        this.messageService.displayErrorMessage(
            this.translate.instant('imgDb.events.createFailed')
        );
    }

    chooseOption(dataList: WizardSlideContentElement) {
        this.databaseTypeParameter = dataList.databaseType;
        this.chosenDbId = dataList.cardValue;
        this.changeWizard(dataList.redirectSlideId);
        this.activeWizardSlideIndex.emit(dataList.redirectSlideId);
    }

    changeWizard(id: number): void {
        this.activeWizardSlide = id;
    }

    onGoBack() {
        this.changeWizard(--this.activeWizardSlide);
    }
}
