import { Component, OnInit } from '@angular/core';
import { UserProfile } from 'src/app/authentication/profile/models/user-profile';
import { UserService } from 'src/app/authentication/services/user.service';
import { PublicApiToken } from '../../models/public-api-token';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-admin-token',
    templateUrl: './admin-token.component.html',
    styleUrls: ['./admin-token.component.scss'],
})
export class AdminTokenComponent implements OnInit {
    constructor(private userService: UserService) {}

    users: UserProfile[] = [];

    tokens: PublicApiToken[] = [];

    selectedUser?: UserProfile;

    userForm: FormControl<UserProfile> = new FormControl();

    ngOnInit(): void {
        this.userService.getUsers().subscribe({
            next: (data) => {
                this.users = data;
                this.users.sort((lft, rgt) =>
                    lft.displayName.localeCompare(rgt.displayName)
                );
                this.selectedUser = this.users[0];
                this.userForm.setValue(this.selectedUser);
            },
            error: (error) => {
                console.log('error' + error);
            },
        });
        this.userForm.valueChanges.subscribe((user) => {
            this.selectedUser = user;
            this.userService.getTokens(user.id).subscribe((tokens) => {
                this.tokens = tokens;
                this.tokens.sort(
                    (t1, t2) => Date.parse(t1.created) - Date.parse(t2.created)
                );
            });
        });
    }

    onTokenAddButtonClick(user: UserProfile) {
        this.userService.addToken(user.id).subscribe((token) => {
            this.tokens.push(token);
        });
    }

    onActiveToggleChange(user: UserProfile, token: PublicApiToken) {
        this.userService
            .activateToken(user.id, token.id, !token.active)
            .subscribe((updatedToken) => {
                token.active = updatedToken.active;
            });
    }

    onTokenDeleteButtonClick(user: UserProfile, token: PublicApiToken) {
        this.userService
            .deleteToken(user.id, token.id)
            .subscribe(
                () => (this.tokens = this.tokens.filter((t) => t !== token))
            );
    }

    onUserSelectInteraction($event: any) {
        $event.target.blur();
    }
}
