import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LanguageCode } from './navigation/menu/components/language-nav-menu/data/laguage-code';
import { ChangeLangEventService } from './navigation/menu/components/language-nav-menu/service/change-lang-event.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    private changeLangSubscription: Subscription;
    usedLanguageCode: LanguageCode = LanguageCode.DE;

    constructor(
        private translate: TranslateService,
        private changeLangEvent: ChangeLangEventService
    ) {
        translate.addLangs([LanguageCode.DE, LanguageCode.EN]);
        translate.setDefaultLang(LanguageCode.DE);
        translate.use(this.usedLanguageCode);
    }

    ngOnInit(): void {
        this.changeLangSubscription = this.changeLangEvent
            .getChangeLang()
            .subscribe((data) => {
                this.usedLanguageCode = data;
                localStorage.setItem('change-language', this.usedLanguageCode);
                this.translate.use(this.usedLanguageCode);
            });
    }

    ngOnDestroy(): void {
        this.changeLangSubscription.unsubscribe();
    }
}
