import {
    Component,
    EventEmitter,
    Output,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ImgWithSelection } from '../model/img-with-selection';
import { Subscription } from 'rxjs';
import { ImgDbService } from '../services/img-db.service';
import { ImgDbZoomService } from '../services/img-db-zoom.service';
import { SelectionButtonMode } from '../img-db.component';
import { ImagesStorageService } from '../services/images-storage.service';
import { take } from 'rxjs/operators';
import { PagingResult } from '../../shared/models/pageable';

@Component({
    selector: 'app-img-db-gallery',
    templateUrl: './img-db-gallery.component.html',
    styleUrls: ['./img-db-gallery.component.scss'],
})
export class ImgDbGalleryComponent implements OnInit, OnDestroy {
    @Output()
    selectButtonStatusChanged: EventEmitter<string> = new EventEmitter();

    currentPage: number = 1;

    images: ImgWithSelection[] = [];
    currentWidth: number;
    totalPages: number;

    subscriptions: Subscription[] = [];

    componentInitialized: boolean = false;

    constructor(
        private service: ImgDbService,
        private zoomService: ImgDbZoomService,
        private storage: ImagesStorageService
    ) {}

    ngOnInit(): void {
        this.storage
            .getImages()
            .pipe(take(1))
            .subscribe((page: PagingResult<ImgWithSelection>) => {
                this.images = page.values;
                this.totalPages = page.totalPages;
                // componentInitialized is used to prevent double set of images through imagesPage$ subscription below
                this.componentInitialized = true;
            });

        this.subscriptions.push(
            this.service.selection.subscribe((sel) => {
                this.handleSelection(sel);
            })
        );
        this.subscriptions.push(
            this.zoomService.widthManageDialog.subscribe(
                (width) => (this.currentWidth = width)
            )
        );

        this.subscriptions.push(
            this.storage.imagesPage$.subscribe(
                (page: PagingResult<ImgWithSelection>) => {
                    if (this.componentInitialized) {
                        this.images = page.values;
                        this.totalPages = page.totalPages;
                        this.currentPage = this.storage.currentPage;
                    }
                }
            )
        );
    }

    selectImage(image: ImgWithSelection) {
        image.isSelected = !image.isSelected;
        if (image.isSelected) {
            const selectedImages = this.images.filter(
                (img: ImgWithSelection) => img.isSelected
            );
            if (selectedImages.length === this.images.length) {
                this.selectButtonStatusChanged.emit(
                    SelectionButtonMode.Deselect
                );
            }
        } else {
            const selectedImages = this.images.filter(
                (img: ImgWithSelection) => img.isSelected
            );
            if (!selectedImages.length) {
                this.selectButtonStatusChanged.emit(SelectionButtonMode.Select);
            }
        }
    }

    handleSelection(isAllSelected: boolean) {
        this.images.forEach((imageSel) => {
            imageSel.isSelected = isAllSelected;
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    getNextPage(pageToFetch: number) {
        if (this.currentPage !== pageToFetch) {
            this.currentPage = pageToFetch;
            this.storage.currentPage = this.currentPage;
            this.storage
                .getImages()
                .pipe(take(1))
                .subscribe((page: PagingResult<ImgWithSelection>) => {
                    this.images = page.values;
                });
        }
    }
}
