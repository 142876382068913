import { Injectable } from '@angular/core';
import {
    ContentItem,
    ContentType,
    SideContainerData,
    SideContainerPosition,
    ToggleMode,
} from '../models/side-container-data';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    sideContainerDataMapping,
    EvaluationState,
    SavedEvaluationState,
    SideContainerStates,
    SavedSideContainerStates,
} from '../evaluation-state';
import { TrainingDTO } from 'src/app/shared/models/training-dto';
import { TrainingType } from 'src/app/shared/enums/training-type.enum';
import { UserCacheService } from 'src/app/xtra/user-cache/user-cache-service';

@Injectable()
export class EvaluationStorageService {
    leftContainerData: BehaviorSubject<SideContainerData> =
        new BehaviorSubject<SideContainerData>(null);
    rightContainerData: BehaviorSubject<SideContainerData> =
        new BehaviorSubject<SideContainerData>(null);

    xAxisLabel: BehaviorSubject<string> = new BehaviorSubject<string>('');
    yAxisLabel: BehaviorSubject<string> = new BehaviorSubject<string>('');
    //state only has initial image index, current value in imageIndex
    state: EvaluationState;

    toggleMode: ToggleMode = ToggleMode.MEDIA;
    trainingId: string;
    trainingType: TrainingType;
    imageIndex: BehaviorSubject<number> = new BehaviorSubject(0);

    categoriesSelections: boolean[][] = new Array(20);

    constructor(private userCacheService: UserCacheService) {}

    private deepCopy(obj: Object) {
        return JSON.parse(JSON.stringify(obj));
    }

    initializeState(training: TrainingDTO, savedState: SavedEvaluationState) {
        this.trainingId = training.uuid;
        this.trainingType = training.trainingType;
        this.state = this.deepCopy(
            sideContainerDataMapping[training.trainingType]
        );
        if (savedState !== null) {
            this.loadState(savedState);
        }
        if (this.trainingType === TrainingType.VISUAL) {
            this.imageIndex.next(this.state.media.imageIndex);
        }
        this.yAxisLabel.next(
            `evaluation.${this.trainingType}.diagrams.${
                this.state.diagrams[SideContainerPosition.RIGHT]
            }.yAxisLabel`
        );
        this.xAxisLabel.next(
            `evaluation.${this.trainingType}.diagrams.${
                this.state.diagrams[SideContainerPosition.RIGHT]
            }.xAxisLabel`
        );
        this.updateSideComponents();
    }

    updateToggleMode(toggleMode: ToggleMode) {
        if (
            this.trainingType === TrainingType.VISUAL &&
            this.toggleMode === ToggleMode.MEDIA
        ) {
            this.cacheCategorySelection();
        }
        this.toggleMode = toggleMode;
        this.updateSideComponents();
    }

    updateSideComponents() {
        this.leftContainerData.next(
            this.state.data[
                this.state[this.toggleMode][SideContainerPosition.LEFT]
            ]
        );

        this.rightContainerData.next(
            this.state.data[
                this.state[this.toggleMode][SideContainerPosition.RIGHT]
            ]
        );
    }

    sideToSideFlow(selectedOption: string) {
        const sideContainer =
            this.state.data[
                this.state[this.toggleMode][SideContainerPosition.LEFT]
            ];

        sideContainer.contentItems.forEach((item: ContentItem) => {
            item.checked = item.value === selectedOption;
        });

        this.state[this.toggleMode][SideContainerPosition.RIGHT] =
            selectedOption;

        this.rightContainerData.next(
            this.state.data[
                this.state[this.toggleMode][SideContainerPosition.RIGHT]
            ]
        );
        if (this.toggleMode === ToggleMode.DIAGRAMS) {
            this.yAxisLabel.next(
                `evaluation.${this.trainingType}.diagrams.${selectedOption}.yAxisLabel`
            );
            this.xAxisLabel.next(
                `evaluation.${this.trainingType}.diagrams.${selectedOption}.xAxisLabel`
            );
        }
    }

    updateSideContainer(selectedOption: string, side: SideContainerPosition) {
        const sideContainer =
            this.state.data[this.state[this.toggleMode][side]];

        this.updateSelection(sideContainer, selectedOption);
        if (side === SideContainerPosition.RIGHT) {
            this.rightContainerData.next(sideContainer);
        } else {
            this.leftContainerData.next(sideContainer);
        }
    }

    updateSelection(sideContainer: SideContainerData, selectedOption: string) {
        sideContainer.contentItems.forEach((item: ContentItem) => {
            if (item.value === selectedOption) {
                item.checked = !item.checked;
            }
        });
    }

    setCategories(categories: string[], imageIndex: number) {
        const sideContainerData: SideContainerData = {
            contentType: ContentType.CHECKBOX,
        } as SideContainerData;
        this.cacheCategorySelection();
        const items: ContentItem[] = [] as ContentItem[];
        this.imageIndex.next(imageIndex);
        categories.forEach((category: string, index: number) => {
            items.push(this.createContentItemForCategory(category, index));
        });

        sideContainerData.contentItems = items;

        this.state.data.categories = sideContainerData;
        this.leftContainerData.next(sideContainerData);
    }

    saveState(): Observable<any> {
        const data = this.state.data;
        const sideContainerSaveStates =
            this.mapSideContainerDataToSaveStates(data);
        let stateDto = {
            toggleMode: this.toggleMode,
            diagrams: this.state.diagrams,
            media: {
                ...this.state.media,
                imageIndex: this.imageIndex.value,
            },
            data: sideContainerSaveStates,
        } as SavedEvaluationState;

        return this.userCacheService.saveData(
            `evaluation_${this.trainingId}_state`,
            stateDto
        );
    }

    private loadState(savedState: SavedEvaluationState) {
        this.toggleMode = savedState['toggleMode'];
        this.state['diagrams'] = savedState['diagrams'];
        this.state.media = savedState.media;
        const savedSideContainersData = savedState.data;
        Object.keys(savedSideContainersData).forEach(
            (sideContainerName: string) => {
                if (
                    sideContainerName !== 'categories' &&
                    Object.keys(this.state.data).includes(sideContainerName)
                )
                    savedSideContainersData[sideContainerName].forEach(
                        (checked: boolean, index: number) => {
                            if (
                                this.state.data[sideContainerName].contentItems
                                    .length > index
                            ) {
                                this.state.data[sideContainerName].contentItems[
                                    index
                                ].checked = checked;
                            }
                        }
                    );
                else {
                    this.categoriesSelections[savedState.media.imageIndex] =
                        savedSideContainersData[sideContainerName];
                }
            }
        );
    }

    private mapSideContainerDataToSaveStates(
        data: SideContainerStates
    ): SavedSideContainerStates {
        let sideContainerSaveStates: SavedSideContainerStates = {};
        Object.keys(data).forEach((sideContainerName) => {
            let selectionStates = [];
            if (
                data[sideContainerName].contentItems === undefined &&
                sideContainerName === 'categories'
            ) {
                selectionStates =
                    this.categoriesSelections[this.imageIndex.value];
            } else {
                data[sideContainerName].contentItems?.forEach(
                    (contentItem: ContentItem) => {
                        selectionStates.push(contentItem.checked);
                    }
                );
            }
            sideContainerSaveStates[sideContainerName] = selectionStates;
        });
        return sideContainerSaveStates;
    }

    private createContentItemForCategory(
        category: string,
        index: number
    ): ContentItem {
        return {
            value: category,
            checked:
                this.categoriesSelections[this.imageIndex.value] === null ||
                this.categoriesSelections[this.imageIndex.value] === undefined
                    ? true
                    : this.categoriesSelections[this.imageIndex.value][index],
            tagForm: ['circle'],
        } as ContentItem;
    }

    private cacheCategorySelection() {
        if (this.leftContainerData.value?.contentItems !== undefined) {
            let selectionState = [];
            this.leftContainerData.value.contentItems.forEach(
                (contentItem: ContentItem) => {
                    selectionState.push(contentItem.checked);
                }
            );
            this.categoriesSelections[this.imageIndex.value] = selectionState;
        }
    }
}
