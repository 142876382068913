<div class="basic-param-wrapper">
    <div class="basic-param-title-wrapper">
        <p class="basic-param-title mb-0">
            {{ 'algorithm.basicFormTitle' | translate }}
        </p>
    </div>

    <div class="row m-0 basic-param-content">
        <div class="col-4 col-xl-3 px-0 bg-color">
            <div class="col-content">
                <app-algorithm-input
                    inputLabel="{{ 'algorithm.gamma.label' | translate }}"
                    type="text"
                    [required]="true"
                    [control]="algorithmForm.get('gamma')"
                    (valueChange)="onValueChange()"
                    [decimalPlaces]="4"
                ></app-algorithm-input>

                <div class="spacer20"></div>

                <app-algorithm-input
                    inputLabel="{{
                        'algorithm.learningRate.label' | translate
                    }}"
                    type="text"
                    [required]="true"
                    [control]="algorithmForm.get('learningRate')"
                    (valueChange)="onValueChange()"
                    [decimalPlaces]="6"
                ></app-algorithm-input>

                <div class="spacer20"></div>

                <app-algorithm-input
                    inputLabel="{{ 'algorithm.bufferSize.label' | translate }}"
                    type="text"
                    [required]="true"
                    [control]="algorithmForm.get('bufferSize')"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>

                <div class="spacer20"></div>

                <app-algorithm-input
                    inputLabel="{{
                        'algorithm.learningStarts.label' | translate
                    }}"
                    type="text"
                    [required]="true"
                    [control]="algorithmForm.get('learningStarts')"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>

                <div class="spacer20"></div>

                <app-algorithm-input
                    inputLabel="{{ 'algorithm.batchSize.label' | translate }}"
                    type="text"
                    [required]="true"
                    [control]="algorithmForm.get('batchSize')"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>
            </div>
        </div>

        <div class="col-4 col-xl-3 px-0 bg-color">
            <div class="col-content">
                <app-algorithm-input
                    inputLabel="{{ 'algorithm.tau.label' | translate }}"
                    type="text"
                    [required]="true"
                    [control]="algorithmForm.get('tau')"
                    (valueChange)="onValueChange()"
                    [decimalPlaces]="4"
                ></app-algorithm-input>

                <div class="spacer20"></div>

                <app-algorithm-input
                    inputLabel="{{
                        'algorithm.gradientSteps.label' | translate
                    }}"
                    type="text"
                    [required]="true"
                    [control]="algorithmForm.get('gradientSteps')"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>

                <div class="spacer20"></div>

                <div class="tryb-input-container">
                    <label class="input-label">
                        {{ 'algorithm.actionNoiseType.label' | translate }}
                    </label>

                    <div
                        class="tryb-dropdown"
                        [ngClass]="{
                            disabled:
                                algorithmForm.get('actionNoiseType').disabled
                        }"
                    >
                        <select
                            id="actionNoiseTypeDropdown"
                            class="mr-1"
                            [formControl]="algorithmForm.get('actionNoiseType')"
                            (change)="onValueChange()"
                            (keyup.enter)="blurInput($event)"
                            (keyup.escape)="blurInput($event)"
                        >
                            <option
                                *ngFor="let type of actionNoiseTypes"
                                [value]="type"
                            >
                                {{ type }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="spacer20"></div>

                <div class="double-input">
                    <label class="input-label">
                        {{ 'algorithm.trainFreq.label' | translate }}*
                    </label>
                    <div class="input-row">
                        <div class="first-container">
                            <app-algorithm-input
                                type="text"
                                [required]="false"
                                [control]="algorithmForm.get('trainFreqValue')"
                                (valueChange)="onValueChange()"
                                [isFloatingPoint]="false"
                            ></app-algorithm-input>
                        </div>
                        <div class="second-container">
                            <div class="tryb-input-container">
                                <div
                                    class="tryb-dropdown"
                                    [ngClass]="{
                                        disabled: true
                                    }"
                                >
                                    <select
                                        class="mr-1"
                                        [formControl]="
                                            algorithmForm.get('trainFreqUnit')
                                        "
                                        (change)="onValueChange()"
                                        (keyup.enter)="blurInput($event)"
                                        (keyup.escape)="blurInput($event)"
                                    >
                                        <option
                                            *ngFor="let type of frequencyTypes"
                                            [value]="type"
                                        >
                                            {{ type }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="spacer20"></div>

                <div class="tryb-input-container">
                    <label class="input-label">
                        {{ 'algorithm.replayBufferClass.label' | translate }}
                    </label>

                    <div
                        class="tryb-dropdown"
                        [ngClass]="{
                            disabled:
                                algorithmForm.get('replayBufferClass').disabled
                        }"
                    >
                        <select
                            class="mr-1"
                            id="replayBufferClassDropDown"
                            [formControl]="
                                algorithmForm.get('replayBufferClass')
                            "
                            (change)="onValueChange()"
                            (keyup.enter)="blurInput($event)"
                            (keyup.escape)="blurInput($event)"
                        >
                            <option
                                *ngFor="let type of replayBufferClasses"
                                [value]="type"
                            >
                                {{ type }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-4 col-xl-3 px-0 bg-color">
            <div class="col-content">
                <app-algorithm-input
                    inputLabel="{{ 'algorithm.entCoef.label' | translate }}"
                    type="text"
                    [control]="algorithmForm.get('entCoef')"
                    (valueChange)="onValueChange()"
                    [allowEmptyValue]="true"
                    [decimalPlaces]="4"
                ></app-algorithm-input>

                <div class="spacer20"></div>

                <app-algorithm-input
                    inputLabel="{{
                        'algorithm.targetUpdateInterval.label' | translate
                    }}"
                    type="text"
                    [control]="algorithmForm.get('targetUpdateInterval')"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>

                <div class="spacer20"></div>

                <app-algorithm-input
                    inputLabel="{{
                        'algorithm.targetEntropy.label' | translate
                    }}"
                    type="text"
                    [control]="algorithmForm.get('targetEntropy')"
                    (valueChange)="onValueChange()"
                    [allowEmptyValue]="true"
                    [decimalPlaces]="4"
                ></app-algorithm-input>

                <div class="spacer50"></div>

                <div class="checkbox-group d-flex align-items-center">
                    <label class="tryb-checkbox">
                        <span class="input-label"
                            >{{
                                'algorithm.optimizeMemoryUsage.label'
                                    | translate
                            }}*</span
                        >
                        <input
                            type="checkbox"
                            id="optimizeMemoryUsageCheckbox"
                            [formControl]="
                                algorithmForm.get('optimizeMemoryUsage')
                            "
                            [checked]="
                                algorithmForm.get('optimizeMemoryUsage').value
                            "
                            (change)="onValueChange()"
                        />
                        <span class="checkmark">
                            <span
                                class="material-icons task_alt"
                                *ngIf="
                                    algorithmForm.get('optimizeMemoryUsage')
                                        .value
                                "
                                >task_alt</span
                            >
                        </span>
                    </label>
                </div>

                <div class="spacer50"></div>

                <div class="checkbox-group d-flex align-items-center">
                    <label class="tryb-checkbox">
                        <span class="input-label"
                            >{{ 'algorithm.useSde.label' | translate }}*</span
                        >
                        <input
                            type="checkbox"
                            id="gSDE"
                            [formControl]="algorithmForm.get('useSde')"
                            [checked]="algorithmForm.get('useSde').value"
                            (change)="onValueChange()"
                        />
                        <span class="checkmark">
                            <span
                                class="material-icons task_alt"
                                *ngIf="algorithmForm.get('useSde').value"
                                >task_alt</span
                            >
                        </span>
                    </label>
                </div>
            </div>
        </div>

        <div class="col-4 col-xl-3 px-0 bg-color">
            <div class="col-content">
                <app-algorithm-input
                    inputLabel="{{
                        'algorithm.sdeSampleFreq.label' | translate
                    }}"
                    type="text"
                    [control]="algorithmForm.get('sdeSampleFreq')"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>

                <div class="spacer50"></div>

                <div class="checkbox-group d-flex align-items-center">
                    <label class="tryb-checkbox">
                        <span class="input-label"
                            >{{
                                'algorithm.useSdeAtWarmup.label' | translate
                            }}*</span
                        >
                        <input
                            type="checkbox"
                            id="useSdeAtWarmupCheckbox"
                            [formControl]="algorithmForm.get('useSdeAtWarmup')"
                            [checked]="
                                algorithmForm.get('useSdeAtWarmup').value
                            "
                            (change)="onValueChange()"
                        />
                        <span class="checkmark">
                            <span
                                class="material-icons task_alt"
                                *ngIf="
                                    algorithmForm.get('useSdeAtWarmup').value
                                "
                                >task_alt</span
                            >
                        </span>
                    </label>
                </div>

                <div class="spacer20"></div>

                <app-algorithm-input
                    inputLabel="{{ 'algorithm.seed.label' | translate }}"
                    type="text"
                    [control]="algorithmForm.get('seed')"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                    [allowEmptyValue]="true"
                ></app-algorithm-input>

                <div class="spacer20"></div>

                <app-algorithm-input
                    inputLabel="{{
                        'algorithm.totalTimesteps.label' | translate
                    }}"
                    type="text"
                    [required]="true"
                    [control]="algorithmForm.get('totalTimesteps')"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>

                <div class="spacer20"></div>

                <app-algorithm-input
                    inputLabel="{{
                        'algorithm.maxEpisodeLength.label' | translate
                    }}"
                    type="text"
                    [required]="true"
                    [control]="algorithmForm.get('maxEpisodeLength')"
                    (valueChange)="onValueChange()"
                    [isFloatingPoint]="false"
                ></app-algorithm-input>
            </div>
        </div>
    </div>
</div>
